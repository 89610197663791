import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

//Components
import Banner from '../components/Banner'
import Main from '../components/Nosotros/main'
import ContactWrapper from '../components/ContactWrapper'
import BannerTop from "../components/Modules/MediaPush/BannerTop"


const IndexPage = () => (
  <Layout>
    <Seo title="Nosotros" />
    <BannerTop page="nosotros"/>
    <Banner 
      section="nosotros"
      title="DIC"
      subtitle="Una empresa familiar"/>
    <Main />
    <ContactWrapper/>
  </Layout>
)

export default IndexPage
