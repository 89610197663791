import React from 'react'

const Banner = ({section, title, subtitle}) => {
  return (
    <section className={`banner-container bg bg-${section}`}>
        { title || subtitle
            ? <div className="container-fluid text-container">
                <div className="d-flex flex-column">
                    { title ? <h1 className="title pb-4">{title}</h1> : '' }
                    { subtitle ? <p className="subtitle mt-4">{subtitle}</p> : '' }
                </div>
            </div> : '' }
    </section>
  )
}

export default Banner