import React from "react";

const VideoTablet = ({videoSrc}) => {

    return (
        <section id="video-tablet">
            <div className="video">{videoSrc}</div>
        </section>
    )
}

export default VideoTablet;