import React, {useEffect} from "react";
import Breadcrumbs from "../breadcrumbs";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { graphql, useStaticQuery } from 'gatsby';
import tabletImg from '../../images/tablet.png';
import VideoTablet from '../VideoTablet';


const Main = () => {

    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            about_us
            sections{
                about{
                    image
                }
            }
        }
    }`)
    
    useEffect(() => {
        AOS.init({ duration : 2000});
    }, [])

    return (
        <section className="about-main">
            <div className="container-fluid">
                <div className="row content-main d-flex align-items-center">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 text-center">
                                <Breadcrumbs
                                    props={[
                                    { name: realEstate.name, route: "/", location: "" },
                                    { name: "Nosotros", route:   "", location: ""},
                                    ]} />
                            </div>
                            <div className="col-12 d-flex flex-column align-items-center">
                                <h1 className="main-header text-center">DEDICADOS A BRINDAR EL MEJOR <br className="d-none d-lg-block" /> SERVICIO INMOBILIARIO</h1>
                                <p className="main-text">DIC PROPIEDADES es una empresa familiar que fue fundada en 1984 por la familia Del Coro Igarzabal Coello, desde esa fecha hasta hoy nos hemos convertido en un referente prestigioso del mercado inmobiliario. Nuestro estilo ÚNICO y PROFESIONAL nos permite mantener el liderazgo en nuestra actividad, con una trayectoria basada en el profesionalismo. Estamos presentes en todo Zona Norte y en la Ciudad Autónoma de Buenos Aires. Nos especializamos en servicios inmobiliarios y estamos en la búsqueda permanente de la excelencia, innovando y actualizando los procesos. Somos apasionados en lo que hacemos, y nuestro principal objetivo, es acompañarte a cumplir los tuyos. CONTACTATE y coordinaremos una entrevista en tu propiedad. Asesorate con DIC Propiedades. Consulta a TU inmobiliaria de siempre. </p>
                                {/* <p className="main-text text-center">[Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quas dolore, dignissimos molestiae nobis maiores perspiciatis laboriosam voluptas consequuntur eveniet laborum adipisci aut accusantium ab dicta eos distinctio alias corporis assumenda!]</p> */}
                            </div>
                            <div className="col-12">
                                <div className="separator"></div>
                            </div>
                            <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <img src={tabletImg} alt="tablet-img" className="tablet-img" />
                                        </div>
                                        <div className="col-12 col-lg-5 offset-lg-1 d-flex align-items-center justify-content-lg-start justify-content-center">
                                            <div className="text-container d-flex flex-column">
                                                <h2 className="title mb-5 text-start">DESCUBRÍ NUESTROS SERVICIOS</h2>
                                                <p className="text text-start">Nuestra prioridad es responder siempre a las necesidades y aspiraciones de nuestros clientes, tanto particulares como empresas constructoras, realizando estrategias de comunicaciones exclusivas y personalizadas en: <br /> - Redes Sociales (Instagram, Facebook, Whatsapp). <br /> - Google Ads. <br /> - Campañas en portales Inmobiliarios líderes. <br /> - Email Marketing con Base de Datos Propia de +250.000 Contactos. <br /> - Tour Virtual 360. <br /> - Servicio de Fotografía Profesional. <br /> - Renderizado 3d. <br /> - Planos de Planta. <br /> - Landing Page Emprendimientos. <br /> - Atención Online las 24hs. <br /><br /> En DIC, estamos donde hay que estar</p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className="col-12">
                                <div className="separator"></div>
                            </div>
                            <div className="col-12 justify-content-center text-center">
                                <h2 className="big-text text-center m-auto mb-5">SOMOS UN EQUIPO PROFESIONAL MULTIDISCIPLINARIO ESPECIALISTAS EN SERVICIOS INMOBILIARIOS</h2>
                                
                                <VideoTablet videoSrc={<iframe src="https://www.youtube.com/embed/gyrhTC69Zbw?controls=0&autoplay=0&rel=0&showinfo=0&modestbranding=1" title="YouTube video player" showinfo="0" rel="0" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
} 

export default Main;